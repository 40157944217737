type ZIndexes = {
  modal: number;
  modalOff: number;
  header: number;
  default: number;
  orderSuccessHeaderImage: number;
  orderSuccessHeaderTick: number;
  infiniteScroll: number;
  categoryStrip: number;
  profileIcon: number;
  squidSearch: number;
  squidCategoryOverlay: number;
  squidCategoryMobileOverlay: number;
  squidMobileSideMenuOverlay: number;
  squidMobileSideMenuOverlayBg: number;
  squidToolTip: number;
};

export const zIndexes: ZIndexes = {
  modal: 60,
  modalOff: -10,
  header: 17,
  default: 0,
  orderSuccessHeaderImage: 15,
  orderSuccessHeaderTick: 16,
  infiniteScroll: -999,
  categoryStrip: 10,
  profileIcon: 10,
  squidSearch: 30,
  squidCategoryOverlay: 50,
  squidCategoryMobileOverlay: 20,
  squidMobileSideMenuOverlay: 50,
  squidMobileSideMenuOverlayBg: 40,
  squidToolTip: 10,
};
